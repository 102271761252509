<template>
  <b-row>
    <b-col class="register-success">
      <div class="register-success__logo company" />

      <main>
        <h1 class="register-success__title">
          Seu e-mail foi verificado com sucesso!
        </h1>
      </main>

      <footer>
        <b-link :to="{ name: 'login' }">
          <span class="register-success__login-return">
            Click aqui para efetuar login.
          </span>
        </b-link>
      </footer>
    </b-col>

    <b-col class="register-background">
      <div class="register-background-text">
        <h1 class="register-background-text__title">
          Anúncie o seu imóvel de forma fácil e acessível
          <span class="register-background-text__title--company">!</span>
        </h1>
        <p class="register-background-text__paragraph">
          Simples, fácil e seguro para qualquer pessoa investir no mercado
          imobiliário.
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BLink } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
  },
  async mounted() {
    try {
      this.key = { c: this.$route.query.c }
      this.sendData = {
        c: decodeURI(this.key.c),
      }
      const response = await this.$http.post(
        '/api/services/app/Account/ActivateEmail',
        this.sendData,
      )
      const success = response.data.result
      if (success) {
        this.$router.push({ name: 'login' })
      }
    } catch (error) {
      this.errors = error.message
    }
  },
}
</script>

<style lang="scss">
@import '../sass/variables.scss';
@import '../sass/register-background-text.scss';
@import '../sass/register-success.scss';

.register-background {
  display: none;

  @media (min-width: 1055px) {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-image: url('~@/assets/images/pages/background_real-state.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
}
</style>
